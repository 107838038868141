<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col cols="12" lg="8">
        <new-perseu-card title="Terapias realizadas">
          <v-row class="px-6 py-6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar por nome ou email do paciente"
              single-line
              hide-details
            ></v-text-field>
          </v-row>

          <v-list v-if="hasTherapies" three-line>
            <v-list-item
              v-for="(therapy, index) in filteredTherapies"
              :key="index"
              class="border-bottom-grey"
              @click="showActions({ item: therapy, index })"
            >
              <v-list-item-avatar>
                <tooltip-icon
                  v-show="isTherapyStatusSynced(therapy)"
                  color="primary"
                  icon="cloud_sync"
                  text="Sincronizado/Processado"
                  large
                />
                <tooltip-icon
                  v-show="isTherapyStatusNotSynced(therapy)"
                  color="warning"
                  icon="sync_disabled"
                  text="Não sincronizado"
                  large
                />
                <tooltip-icon
                  v-show="isTherapyStatusInQueue(therapy)"
                  color="info"
                  icon="playlist_add_check"
                  text="Na fila para processamento"
                  large
                />
                <tooltip-icon
                  v-show="isTherapyStatusInQueueWithError(therapy)"
                  color="error"
                  icon="sync_problem"
                  text="Erro ao realizar processamento"
                  large
                />
                <tooltip-icon
                  v-show="isTherapyStatusInProgress(therapy)"
                  color="warning"
                  icon="pending"
                  text="Não finalizado"
                  large
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ therapy.patientName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ therapy.realizedAt | formatDateTime }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span v-if="therapy.protocol"> Terapia Finalizada </span>
                  <div v-else>
                    <span v-if="isTherapyStatusInQueue(therapy)"
                      >Em fila de processamento</span
                    >
                    <div v-else>
                      <span v-if="!isTherapyStatusInProgress(therapy)"
                        >Terapia finalizada/não sincronizada</span
                      >
                      <span v-if="isTherapyStatusInProgress(therapy)"
                        >Terapia não finalizada/não sincronizada</span
                      >
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon class="mt-3">open_in_new</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <template v-else>
            <empty-list text="Nenhuma terapia realizada ainda." />
          </template>
          <div v-if="!filteredTherapies.length && search">
            <empty-list text="Nenhum resultado para a pesquisa." />
          </div>
        </new-perseu-card>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="dialogActions">
      <v-sheet>
        <div class="pa-3">
          <span class="title">Ações</span>
        </div>
        <v-divider></v-divider>
        <v-list class="text-center">
          <v-list-item
            :disabled="!isSelectedTherapyNotSynced"
            @click="syncTherapy"
          >
            <v-list-item-title>
              <v-icon>sync</v-icon>
              Sincronizar
              <span v-show="!isSelectedTherapyNotSynced">
                (Terapia não concluída ou já sincronizada)
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item :disabled="!isSelectedTherapyInProgress" @click="resume">
            <v-list-item-title>
              <v-icon>redo</v-icon>
              Continuar terapia
              <span v-show="!isSelectedTherapyInProgress">
                (Terapia concluída)
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            :disabled="!isSelectedTherapySynced"
            @click="viewDiagnosis"
          >
            <v-list-item-title>
              <v-icon>pageview</v-icon>
              Ver diagnóstico
              <span v-show="!isSelectedTherapySynced">
                (Terapia ainda não concluída)
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            :disabled="
              !isSelectedTherapyNotSynced && !isSelectedTherapyInProgress
            "
            @click="deleteTherapy"
          >
            <v-list-item-title>
              <v-icon>delete_forever</v-icon>
              Excluir terapia
              <span
                v-show="
                  !isSelectedTherapyNotSynced && !isSelectedTherapyInProgress
                "
              >
                (Terapia já concluida e sincronizada)
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
    <fixed-button text="Nova terapia" icon="note_add" @click="createTherapy" />
  </div>
</template>

<script>
import { findByTherapist } from "@/services/external/therapies-service";
import { TherapyStatusEnum } from "@/domains/therapy/shared/application/therapy-status.enum";
import _ from "lodash";

export default {
  components: {
    /* "chip-synced": () => import("@/presentation/therapy-list/ChipSynced"),
    "chip-progress": () => import("@/presentation/therapy-list/ChipProgress") */
  },
  data: () => ({
    therapies: [],
    filteredTherapies: [],
    headers: [
      { text: "Número", value: "id" },
      { text: "Paciente", value: "patient.name" },
      { text: "Data", value: "createdAt", width: "150" },
      { value: "synced", width: "150", sortable: false },
      { value: "finished", width: "150", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        width: "150",
      },
    ],
    dialogDiagnosis: false,
    therapy: {},
    dialogActions: false,
    selectedItem: {
      index: "",
    },
    search: "",
    debouncedFilter: _.debounce(function () {
      this.filterTherapies();
    }, 400),
  }),
  computed: {
    isSelectedTherapySynced() {
      return this.selectedItem.status === TherapyStatusEnum.SYNCED;
    },
    isSelectedTherapyInProgress() {
      return this.selectedItem.status === TherapyStatusEnum.IN_PROGRESS;
    },
    isSelectedTherapyNotSynced() {
      return this.selectedItem.status === TherapyStatusEnum.NOT_SYNCED;
    },
    hasTherapies() {
      return this.therapies && this.therapies.length > 0;
    },
  },
  watch: {
    search() {
      this.debouncedFilter();
    },
  },
  created() {
    this.find();
  },
  methods: {
    filterTherapies() {
      if (!this.search) {
        this.filteredTherapies = this.therapies
          .slice()
          .sort(
            (a, b) =>
              new Date(b.realizedAt).getTime() -
              new Date(a.realizedAt).getTime()
          );
      }
      const searchLower = this.search.toLowerCase();
      this.filteredTherapies = this.therapies
        .filter(
          (therapy) =>
            therapy.patientName
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(searchLower) ||
            (therapy.email && therapy.email.toLowerCase().includes(searchLower))
        )
        .sort(
          (a, b) =>
            new Date(b.realizedAt).getTime() - new Date(a.realizedAt).getTime()
        );
    },
    createTherapy() {
      this.$store.dispatch("therapy/newTherapy");
    },
    showActions({ index, item }) {
      this.selectedItem = {
        index,
        ...item,
      };
      this.dialogActions = true;
    },
    resume() {
      this.$store.dispatch("therapy/loadTherapy", this.selectedItem.therapy);
      this.$router.push({ name: "therapys.stepper" });
    },
    async find() {
      try {
        this.$store.dispatch("loading/openDialog");
        this.therapies = await findByTherapist();
        this.filteredTherapies = this.therapies;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    viewDiagnosis() {
      this.dialogActions = false;
      const { protocol } = this.selectedItem;
      this.$router.push({ name: "therapistReview", query: { protocol } });
    },
    async syncTherapy() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { index, therapy } = this.selectedItem;
        const newItem = await this.$store.dispatch(
          "therapy/syncOneTherapy",
          therapy
        );
        this.therapies.splice(index, 1);
        this.therapies.unshift(newItem);
        this.dialogActions = false;
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async deleteTherapy() {
      try {
        this.$store.dispatch("loading/openDialog");
        await this.$store.dispatch("therapy/deleteTherapy", {
          index: this.selectedItem.index,
          therapy: this.selectedItem.therapy,
        });
        this.dialogActions = false;
        this.$toasted.global.defaultSuccess();
        this.therapies.splice(this.selectedItem.index, 1);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    isTherapyStatusSynced(therapy) {
      return therapy.status === TherapyStatusEnum.SYNCED;
    },
    isTherapyStatusInQueue(therapy) {
      return therapy.status === TherapyStatusEnum.IN_QUEUE;
    },
    isTherapyStatusInQueueWithError(therapy) {
      return therapy.status === TherapyStatusEnum.IN_QUEUE_WITH_ERROR;
    },
    isTherapyStatusNotSynced(therapy) {
      return therapy.status === TherapyStatusEnum.NOT_SYNCED;
    },
    isTherapyStatusInProgress(therapy) {
      return therapy.status === TherapyStatusEnum.IN_PROGRESS;
    },
  },
};
</script>

<style scoped></style>
